import React from "react";
import AccessHolderList from "../../components/AccessHolders/AccessHolderList";
import { Switch, useHistory } from "react-router-dom";
import { useStyles } from "./styles";
import ProtectedRoute from "../../components/ProtectedRoute";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import CreateEditAccessHolder from "../../components/AccessHolders/CreateEditAccessHolder";

const  AccessHolderPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { facilityID } = useCurrentFacility();

  const handleAdd = () => {
    history.push("/contracts/accessholders/details");
  };

  const handleAccessHolderChange = (accessHolderID) => {
    history.push("/contracts/accessholders/details", { accessHolderID });
  };

  const handleDelete = () => {
    history.push("/contracts/accessholders");
  };

  return (
    <div className={classes.root}>
      <Switch>
        <ProtectedRoute
          exact
          path="/contracts/accessholders"
          permissions={["accessholders.view"]}
        >
          <AccessHolderList
            onAddClick={handleAdd}
            onSelect={handleAccessHolderChange}
          />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path="/contracts/accessholders/details"
          permissions={["accessholders.view"]}
        >
          <CreateEditAccessHolder
            accessHolderID={history.location.state?.accessHolderID}
            facilityID={facilityID}
            onDelete={handleDelete}
          />
        </ProtectedRoute>
      </Switch>
    </div>
  );
};

export default AccessHolderPage;
