import React, { useState, useCallback } from "react";
import AccessGroupList from "../../../components/AccessGroups/List";
import AccessGroupForm from "../../../components/Forms/AccessGroup";
import { useStyles } from "./styles";
import ProtectedRoute from "../../../components/ProtectedRoute";
import { Button, Dialog, DialogContent, Typography, Box, Grid, IconButton, Collapse } from "@material-ui/core";
import clsx from "clsx";
import useHasPermissions from "../../../hooks/useHasPermissions";
import SearchBar from "../../../components/SearchBar";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import AccessGroupFilters from "../../../components/Filters/AccessGroupFilters";

const AccessGroupsPage = () => {
  const classes = useStyles();
  const [newData, setNewData] = useState(false);
  const [formState, setFormState] = useState({
    open: false
  });
  const { hasPermissions } = useHasPermissions();
  const accessGroupsAdd = hasPermissions(["accessgroups.add"]);
  const [accessGroupModalSearchTerm, setAccessGroupModalSearchTerm] = useState("");
  const [accessGroupTypeFilter, setAccessGroupTypeFilter] = useState("");
  const [filtersOpen, setFiltersOpen] = useState(false);

  const handleAddAccessGroup = () => {
    setFormState({ open: true });
  };

  const handleEditSubmit = () => {
    setNewData(prev => !prev);
    setFormState({ open: false });
  };

  const handleAccessGroupSearchChange = val => {
    setAccessGroupModalSearchTerm(val);
  };

  const handleGroupTypeFilterChange = (val) => {
    setAccessGroupTypeFilter(val);
  };

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const handleFilterClear = () => {
    setAccessGroupTypeFilter("");
  };

  return (
    <div className={classes.root}>
      <ProtectedRoute exact path="/groups" permissions={["accessgroups.view"]}>
        <Box className="pageable-entity">
          <Grid container spacing={2} className={clsx(classes.header)}>
            <Grid item xs={6} lg={9}>
              <SearchBar
                className={classes.searchBar}
                data-id="accessgroup-assign-search"
                onChange={handleAccessGroupSearchChange}
                label="Access Groups"
                placeholder="Search by Name"
              />
            </Grid>
            <IconButton
              className={clsx("pageable-filter-button")}
              color="primary"
              onClick={toggleFilters}
            >
              <FilterListIcon fontSize="large" className={clsx(classes.icon)} />
            </IconButton>
            <Grid item>
              {accessGroupsAdd && (
                <Button
                  data-id="accessgroup-add"
                  variant="contained"
                  color="primary"
                  onClick={handleAddAccessGroup}
                  startIcon={<AddIcon />}
                >
                  Add Access Group
                </Button>
              )}
            </Grid>
            <Grid item xs={12} className={clsx(classes.xcontentRoot)}>
              <Collapse
                className={clsx(classes.xfilterContainer)}
                in={filtersOpen}
                unmountOnExit
              >
                <AccessGroupFilters
                  groupTypeFilter={accessGroupTypeFilter}
                  onGroupTypeChange={handleGroupTypeFilterChange}
                  onFilterClear={handleFilterClear}
                />
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <AccessGroupList
                role="dialog-access-group-list"
                className={classes.accessGroupList}
                mode="manage"
                searchTerm={accessGroupModalSearchTerm}
                newData={newData}
                groupTypeFilter={accessGroupTypeFilter}
              />
            </Grid>
          </Grid>
        </Box>
        <Dialog
          data-id="accessgroup-dialog"
          role="accessgroup-dialog"
          fullWidth
          maxWidth="md"
          open={formState.open}
        >
          <DialogContent>
            <AccessGroupForm
              data={formState.accessGroup}
              onCancel={() => setFormState({ open: false })}
              onSubmit={handleEditSubmit}
            />
          </DialogContent>
        </Dialog>
      </ProtectedRoute>
    </div>
  );
};

export default AccessGroupsPage;
